<template>
  <section class="self-assessment">
    <div class="row">
      <div :class="[step === 0 ? 'col-24' : 'col-18  col-md-24']">
        <ContentBox>
          <div v-if="step > 0" class="col-24  hide-md-and-up  -light-green  display-flex  align-items-center  mb-m  mb-xs-xs  pt-sm-xxs  pb-sm-xxs">
            <span class="step  font-weight-semibold  pr-sm-xs  pr-lg-xs">{{ $t('selfAssessment.progress.step') }} {{ progress }}</span>
          </div>
          <transition name="fade">
            <div v-if="step === 0" key="step-0">
              <div class="row  display-flex">
                <div class="col-14  col-lg-24  display-flex  flex-column  justify-content-between">
                  <div>
                    <div class="mb-m" :class="{'display-flex  align-items-center': !$matchMedia.sm}">
                      <div class="icon-circle-110  color-green-100  pos-relative  mr-s">
                        <Icon class="-size-74  -inline  color-green-300  pos-absolute  -transform-center">
                          <CommentsSVG></CommentsSVG>
                        </Icon>
                      </div>
                      <h3 class="h3  mb-0  mt-0  font-weight-normal">{{ $t('selfAssessment.steps.pre.headline') }}</h3>
                    </div>
                    <p class="font-weight-medium  font-size-l"
                       v-for="(paragraph, key) in $t('selfAssessment.steps.pre.text')"
                       :key="`p-${key}`"
                       v-html="paragraph"></p>
                  </div>
                  <div :class="{'text-right': $matchMedia.md}">
                    <Button class="-orange  mt-s"
                            :class="{'-full-width  text-center  mt-xs-xs': $matchMedia.xs}"
                            @click.native="step += 1; scrollToTop();">
                      {{ $t('selfAssessment.buttons.start') }}
                    </Button>
                  </div>
                </div>
                <div v-if="!$matchMedia.sm" class="col-8  col-offset-2  col-lg-24  col-sm-offset-0  hide-md">
                  <img src="@/assets/graphics/login.jpg" alt="">
                </div>
              </div>
            </div>
            <div v-if="step === 1" key="step-1">
              <div class="row">
                <div class="col-18  col-lg-24">
                  <div class="display-flex  align-items-center">
                    <Icon class="-size-28  mr-xs">
                      <SchoolSVG></SchoolSVG>
                    </Icon>
                    <h4 class="mt-0  mb-0">{{ $t('selfAssessment.steps.experiences.headline' )}}</h4>
                  </div>
                  <p class="mt-xs  font-size-base  font-weight-medium">{{ $t('selfAssessment.steps.experiences.description.paragraph') }}</p>
                </div>
              </div>
              <div class="row  mt-m-less">
                <div class="col-14  col-offset-10  pos-relative  hide-lg">
                  <div class="years-bar">
                    <p v-for="year in years" :key="year" class="font-weight-medium">{{year >= 10 ? '>' : ''}} {{year}} {{ $t('abbreviations.year.short') }}</p>
                  </div>
                </div>
              </div>
              <div v-for="(exp, key) in experiences" :key="key" class="row  input-range-row-border" :class="{'display-flex  align-items-center': !$matchMedia.lg}">
                <div class="col-10  col-lg-24  mb-lg-xs" :class="{'display-flex  justify-content-between': $matchMedia.lg}">
                  <span class="font-weight-semibold">{{ $t(`categories.experiences.${key}`) }}</span>
                  <span v-if="$matchMedia.lg" class="font-weight-semibold">{{ experiences[key] }} {{ $t('abbreviations.year.short') }}</span>
                </div>
                <div class="col-14  col-lg-24">
                  <div class="pos-relative">
                    <VueSlideBar
                      v-model="experiences[key]"
                      :min="0"
                      :max="10"
                      :speed="0.3"
                      :line-height="10"
                      :paddingless="true"
                      :labelStyles="{ color: '#4a4a4a', backgroundColor: '#006ecc' }"
                      :processStyle="{ backgroundColor: '#006ecc' }">
                      <template slot="tooltip">
                        <div class="tooltip-text-wrapper">
                          <span v-if="!$matchMedia.lg" class="tooltip-text">{{experiences[key] >= 10 ? '>' : ''}} {{ experiences[key] }}</span>
                        </div>
                      </template>
                    </VueSlideBar>
                    <div class="line-wrapper">
                      <div class="line" v-for="year in years" :key="year"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-right">
                <p class="color-error  mt-s" v-if="$v.experiences.$error">{{ $t('selfAssessment.steps.experiences.error') }}</p>
                <Button class="-orange  mt-m  mb-xs"
                        :class="{ '-disabled': $v.experiences.$invalid, '-full-width  text-center': $matchMedia.xs }"
                        @click.native="stepHandler">
                  {{ $t('selfAssessment.buttons.next') }}
                </Button>
              </div>
            </div>
            <div v-if="step === 2" key="step-2">
              <div class="row  mb-s">
                <div class="col-18  col-lg-24">
                  <div class="display-flex  align-items-center">
                    <Icon class="-size-28  mr-xs">
                      <IndustrySVG></IndustrySVG>
                    </Icon>
                    <h4 class="mt-0  mb-0">{{ $t('selfAssessment.steps.industries.headline' )}}</h4>
                  </div>
                  <p class="mt-xs  font-size-base  font-weight-medium" v-html="$t('selfAssessment.steps.industries.description.paragraph')"></p>
                </div>
              </div>
              <div class="row  mt-m-less  hide-lg">
                <div class="col-14  col-offset-10  pos-relative">
                  <div class="years-bar">
                    <p v-for="choice in industryChoices" :key="choice" class="font-weight-medium">
                      {{ $t(`selfAssessment.steps.industries.choices.${choice}`) }} {{ choice === 'none' ? '' : $t('abbreviations.year.short') }}
                    </p>
                  </div>
                </div>
              </div>
              <div v-for="(industry, groupkey) in industries" :key="groupkey" class="row  input-range-row-border" :class="{'display-flex  align-items-center': !$matchMedia.lg}">
                <div class="col-10  col-lg-24  mb-lg-xs" :class="{'display-flex  justify-content-between': $matchMedia.lg}">
                  <div class="font-weight-semibold">{{ $t(`categories.industries.main.${groupkey}`) }}</div>
                  <span v-if="$matchMedia.lg" class="font-weight-semibold">{{ industries[groupkey] === 'none' ? 0 : $t(`selfAssessment.steps.industries.choices.${industries[groupkey]}`) }}</span>
                </div>
                <div class="col-14  col-lg-24">
                  <div class="pos-relative">
                    <VueSlideBar
                      :data="industryChoices"
                      v-model="industries[groupkey]"
                      :speed="0.3"
                      :line-height="10"
                      :paddingless="true"
                      :labelStyles="{ color: '#4a4a4a', backgroundColor: '#006ecc' }"
                      :processStyle="{ backgroundColor: '#006ecc' }">
                      <template slot="tooltip">
                        <div class="tooltip-text-wrapper">
                          <span class="tooltip-text" v-if="!$matchMedia.lg">{{ industries[groupkey] === 'none' ? 0 : $t(`selfAssessment.steps.industries.choices.${industries[groupkey]}`) }}</span>
                        </div>
                      </template>
                    </VueSlideBar>
                    <div class="line-wrapper">
                      <div class="line" v-for="industry in industryChoices" :key="industry"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-right">
                <p class="color-error  mt-s" v-if="$v.industries.$error">{{ $t('selfAssessment.steps.operationals.error') }}</p>
                <Button class="-orange  -outlined  mt-xs-m"
                        :class="{'-full-width  text-center': $matchMedia.xs}"
                        v-if="step > 1"
                        @click.native="step -= 1">
                  {{ $t('selfAssessment.buttons.back') }}
                </Button>
                <Button class="-orange  mt-m  ml-s  ml-xs-0  mt-xs-xs"
                        :class="{ '-disabled': $v.industries.$invalid, '-full-width  text-center': $matchMedia.xs }"
                        @click.native="stepHandler">
                  {{ $t('selfAssessment.buttons.next') }}
                </Button>
              </div>
            </div>
            <div v-if="step === 3" key="step-3">
              <div class="row">
                <div class="col-18  col-lg-24">
                  <div class="display-flex  align-items-center">
                    <Icon class="-size-28  mr-xs">
                      <BadgeSVG></BadgeSVG>
                    </Icon>
                    <h4 class="mt-0  mb-0">{{ $t('selfAssessment.steps.operationals.headline' )}}</h4>
                  </div>
                  <p class="mt-xs  font-size-base  font-weight-medium" v-html="$t('selfAssessment.steps.operationals.description.paragraph' )"></p>
                </div>
              </div>
              <div class="row  mt-m  mb-xs">
                <div class="col-24">
                  <div class="font-weight-semibold  font-size-base">{{ $t('selfAssessment.steps.operationals.mainCategory') }}</div>
                </div>
              </div>
              <div class="row">
                <div v-for="(operational) in operationalChoices" :key="operational" class="col-12  col-sm-24  border-top-bottom-blue-200  pt-xs  pb-xs">
                  <InputRadio class="-medium"
                              :choice="operational"
                              name="role"
                              :disabled="operationals.includes(operational)"
                              v-model="selectedOperationals.main">
                    <template slot="label">
                      <span class="input-radio-label  font-weight-medium  ml-xxs">{{ $t(`categories.operationals.main.${operational}`) }}</span>
                    </template>
                  </InputRadio>
                </div>
              </div>
              <div class="row  mt-m">
                <div class="col-24">
                  <div class="font-weight-semibold  font-size-base  mb-xxs">{{ $t('selfAssessment.steps.operationals.subCategory.label') }}</div>
                </div>
                <div class="col-12  col-sm-24">
                  <span class="color-blue-600  font-size-xs  font-weight-semibold">{{ $t('selfAssessment.steps.operationals.subCategory.first') }}</span>
                  <InputSelect class="-light"
                               name="role"
                               :options="$t('categories.operationals.main')"
                               :disabled="operationals.length < 1"
                               v-model="selectedOperationals.side[0]">
                    <option value="" selected>{{ $t('selfAssessment.steps.operationals.placeholder') }}</option>
                    <template v-slot:options>
                      <option v-for="(value, key) in $t('categories.operationals.main')"
                              :key="key"
                              :disabled="operationals.includes(key)"
                              :value="key">{{ value }}</option>
                    </template>
                  </InputSelect>
                </div>
                <div class="col-12  col-sm-24">
                  <span class="color-blue-600  font-size-xs  font-weight-semibold">{{ $t('selfAssessment.steps.operationals.subCategory.second') }}</span>
                  <InputSelect class="-light"
                               name="role"
                               :options="$t('categories.operationals.main')"
                               :disabled="operationals.length < 2"
                               v-model="selectedOperationals.side[1]">
                    <option value="" selected>{{ $t('selfAssessment.steps.operationals.placeholder') }}</option>
                    <template v-slot:options>
                      <option v-for="(value, key) in $t('categories.operationals.main')"
                              :key="key"
                              :disabled="operationals.includes(key)"
                              :value="key">{{ value }}</option>
                    </template>
                  </InputSelect>
                </div>
              </div>
              <div class="text-right">
                <p class="color-error  mt-s" v-if="$v.operationals.$error">{{ $t('selfAssessment.steps.operationals.error') }}</p>
                <Button class="-orange  -outlined  mt-xs-m"
                        :class="{'-full-width  text-center': $matchMedia.xs}"
                        v-if="step > 1"
                        @click.native="step -= 1">
                  {{ $t('selfAssessment.buttons.back') }}
                </Button>
                <Button class="-orange  mt-m  ml-s  ml-xs-0  mt-xs-xs"
                        :class="{ '-disabled': $v.operationals.$invalid, '-full-width  text-center': $matchMedia.xs }"
                        @click.native="submitHandler">
                  {{ $t('selfAssessment.buttons.submit') }}
                </Button>
                <div class="mt-s" v-if="submitStatus !== false">
                  <div v-if="submitStatus === 'PENDING'">{{ $t('status.submit.loading') }}...</div>
                  <div v-if="submitStatus === 'ERROR'" class="color-error">&cross; {{ $t('error.submit.couldNotSave') }}</div>
                </div>
              </div>
            </div>
          </transition>
        </ContentBox>
      </div>
      <div v-if="step > 0" class="col-6  hide-md">
        <slot>
          <ContentBox class="-light-green  pos-relative  -overflow-hidden">
            <div class="progress">
              <p class="step">{{ $t('selfAssessment.progress.step') }} {{ progress }}</p>
            </div>
            <img class="self-assessment-image" src="@/assets/graphics/self-assessment.png" alt="">
          </ContentBox>
        </slot>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';

import Button from '@/components/atoms/Button.vue';
import Icon from '@/components/atoms/Icon.vue';
import ContentBox from '@/components/elements/ContentBox.vue';
import InputRadio from '@/components/elements/inputs/InputRadio.vue';
import InputSelect from '@/components/elements/inputs/InputSelect.vue';

import SchoolSVG from '@/assets/icons/school.svg';
import IndustrySVG from '@/assets/icons/industry.svg';
import BadgeSVG from '@/assets/icons/badge.svg';
import CommentsSVG from '@/assets/icons/comments.svg';

const minOneSum = (value) => Object.values(value).reduce((acc, val) => acc + val) > 0;
const minOneChoice = (value) => Object.values(value).findIndex((val) => val !== 'none') >= 0;
const minLength = (value) => ((value.length >= 1));

export default {
  name: 'SelfAssessment',
  data() {
    return {
      steps: ['experiences', 'industries', 'operationals'],
      step: 0,
      years: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      industryChoices: Object.keys(this.$t('selfAssessment.steps.industries.choices')),
      operationalChoices: Object.keys(this.$t('categories.operationals.main')),
      experiences: {},
      industries: {},
      selectedOperationals: {
        main: '',
        side: [],
      },
      group: '',
      submitStatus: false,
    };
  },
  validations: {
    experiences: {
      minOneSum,
    },
    industries: {
      minOneChoice,
    },
    operationals: {
      minLength,
    },
  },
  computed: {
    ...mapState([
      'user',
      'usermeta',
      'status',
    ]),

    ...mapGetters([
      'loggedIn',
    ]),

    progress() {
      return `${this.step} / ${this.steps.length}`;
    },

    operationals() {
      return [
        ...(this.selectedOperationals.main) && [this.selectedOperationals.main],
        ...this.selectedOperationals.side.filter((val) => val !== ''),
      ];
    },

    main_industries() {
      return Object.keys(this.industries).filter((key) => (this.industries[key] !== 'none'));
    },
  },
  created() {
    Object.keys(this.$t('categories.experiences')).forEach((key) => {
      this.$set(this.experiences, key, this.user.experiences[key] || 0);
    });

    Object.keys(this.$t('categories.industries.main')).forEach((key) => {
      this.$set(this.industries, key, this.user.industries[key] || 'none');
    });

    if (this.user.main_operationals.length > 0) {
      this.$set(this.selectedOperationals, 'main', this.user.main_operationals[0]);
      this.$set(this.selectedOperationals, 'side', this.user.main_operationals.slice(1));
    }
  },
  methods: {
    submitHandler() {
      this.stepHandler();

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.submitStatus = 'PENDING';
        this.submitSelfAssess();
      }
    },

    stepHandler() {
      this.$v[this.steps[this.step - 1]].$touch();
      if (!this.$v[this.steps[this.step - 1]].$invalid) {
        this.updateUser();

        if (this.step < 3) {
          this.step += 1;
        }

        this.scrollToTop();
      }
    },

    updateUser() {
      this.$store.commit('updateUser', {
        experiences: this.experiences,
        industries: this.industries,
        main_operationals: this.operationals,
        main_industries: this.main_industries,
      });
    },

    submitSelfAssess() {
      this.$store.dispatch('selfAssessUser')
        .then((r) => r.data)
        .then((data) => {
          this.group = data.group;
          if (this.group !== 'pending') {
            this.$router.push({ name: 'Profile', params: { lang: this.$i18n.locale } });
          }
        })
        .catch(() => {
          this.submitStatus = 'ERROR';
        });
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  mixins: [validationMixin],
  components: {
    Button,
    ContentBox,
    InputRadio,
    InputSelect,
    Icon,
    SchoolSVG,
    IndustrySVG,
    BadgeSVG,
    CommentsSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/self-assessment.scss"></style>
